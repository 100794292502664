import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import { navigate } from "gatsby"

import styles from "./utils/medensure.module.scss"
import Layout from "layout/Layout"
import Message from "elements/Message"
import Container from "layout/Container"

import { isBrowser } from "../../services/general"

const medensureServices = [
  "Update personal details",
  "Upload updated prescription",
  "Cancel refills",
  "Book a free teleconsult",
]

const MedEnsure = ({ pageContext, location }) => {
  const urlSearchParams = new URLSearchParams(location.search)
  const client = urlSearchParams.get("client") || ""

  const [selectedServices, setSelectedServices] = useState([])

  const handleClickService = (service) => {
    if (selectedServices.includes(service)) {
      setSelectedServices(
        [...selectedServices].filter(
          (selectedService) => selectedService !== service
        )
      )
    } else {
      setSelectedServices([...selectedServices, service])
    }
  }

  const handleSubmit = () => {
    if (isBrowser()) {
      sessionStorage.setItem(
        "medensureServices",
        JSON.stringify(selectedServices)
      )
    }
    navigate(pageContext.nextPath)
  }

  useEffect(() => {
    switch (client) {
      case "Foundever":
      case "foundever":
      case "Sitel":
      case "sitel":
        window.location.href = process.env.GATSBY_FOUNDEVER_REFILLS_REDIRECT_URL
        break
      case "Accenture":
      case "accenture":
        window.location.href = process.env.GATSBY_ACCENTURE_REFILLS_REDIRECT_URL
        break
      case "Maxicare":
      case "maxicare":
        window.location.href = process.env.GATSBY_MAXICARE_REFILLS_REDIRECT_URL
        break
      default:
    }
  }, [client])

  useEffect(() => {
    if (isBrowser()) {
      if (client) sessionStorage.setItem("company", client)
      let currentMedensureServices = JSON.parse(
        sessionStorage.getItem("medensureServices") || "[]"
      )
      setSelectedServices(currentMedensureServices)
    }
  }, [])

  return (
    <Layout
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      path="/medensure"
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered fullhd={7}>
        <h2 className="has-text-left mb-0 has-text-primary pb-1">
          <div>
            <p className="is-size-3 is-size-4-mobile has-text-secondary">
              MedEnsure
            </p>
            <p className="is-size-2 is-size-3-mobile has-text-primary">
              Prescription Update and Teleconsult Form
            </p>
          </div>
          <h4 className="subtitle is-size-5-mobile has-text-grey has-text-left my-1 is-size-5">
            Thank you for participating in MedGrocer’s MedEnsure Program. We're
            excited to offer you a convenient way to keep your prescription
            up-to-date and get your refills.
          </h4>
        </h2>
        <Message color="light">
          <div className="mb-1">
            Through this form, you can update your personal details, upload your
            latest prescription, cancel your refills, and schedule a free
            teleconsult with our doctors - all in one go. Just fill out the form
            and we'll take care of the rest.
          </div>
          <div>
            Please select all that apply to you. We will redirect you to the
            relevant pages based on your selection.
          </div>
        </Message>
        {medensureServices.map((medensureService) => (
          <div
            style={{ border: "1px solid #cfe1e1", borderRadius: "4px" }}
            className="p-1 mb-1 is-flex is-align-items-center is-clickable"
            onClick={() => handleClickService(medensureService)}
          >
            <div
              style={{
                border: "1px solid #363636",
                height: "24px",
                width: "24px",
                borderRadius: "4px",
              }}
              className="is-flex is-justify-content-center is-align-items-center"
            >
              {selectedServices?.includes(medensureService) ? (
                <FontAwesomeIcon icon={faCheck} className="has-text-primary" />
              ) : null}
            </div>
            <p className="is-size-6 ml-1">{medensureService}</p>
          </div>
        ))}
        <div className="buttons is-centered">
          <button
            className={classNames(
              "button px-2 px-2-mobile mr-1 mr-1-mobile",
              styles["button_next"]
            )}
            disabled={selectedServices.length === 0}
            onClick={handleSubmit}
          >
            Next
          </button>
        </div>
      </Container>
    </Layout>
  )
}

export default MedEnsure
